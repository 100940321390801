.text-warping {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.table-caption-hover:hover {
  color: rgb(204, 204, 204);
}

/* --------------- Dialog and Toast------------------- */

.dialog-block {
  background-color: #282c3477;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .toast-block {
    position: fixed;
    bottom: 50px;
    left: 2%;
    right: 2%;
  }
}

/* dekstop */
@media only screen and (min-width: 601px) {
  .toast-block {
    position: fixed;
    bottom: 100px;
    left: 30%;
    right: 30%;
  }
}

/* other */

.list-group-item-menu {
  background-color: transparent;
  color: white;
  border-style: none;
}

.list-menu-navigation1.active {
  background-color: white;
  color: #018730;
  font-weight: bold;
}

.list-group-item-menu {
  border-radius: 10px !important;
}

.bg-color-green {
  background-color: #018730;
}

.bg-color-yellow {
  background-color: #f4b21e;
}

.bg-color-white {
  background-color: #ffffff;
}

.color-green {
  color: #018730;
}

.color-yellow {
  color: #f4b21e;
}

.color-white {
  color: #ffffff;
}

.td-style-1 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.menu-item-sidebar {
  /* background-color: white; */
  color: white;
  margin-bottom: 2px;
  cursor: default;
}
.menu-item-sidebar.active {
  background-color: white;
  color: #198754 !important;
}
.menu-item-sidebar:hover {
  background-color: white;
  color: #198754 !important;
}
.menu-item-sidebar.sidebar-item-invisible:hover {
  background-color: white;
  color: #198754 !important;
}

.bg-sidebar-block {
  background-color: #146f44;
}

#tagSideBar {
  z-index: 2;
  position: fixed;
  top: 0px;
  bottom: 0px;
  background-color: #198754;
  transition: margin 0.3s;
  transition: width 0.2s;
}

.sidebar-item-invisible.active {
  display: none;
}

.btn-border-none {
  border-style: none;
}

.td-actions {
  min-width: 110px;
  width: 120px;
}

.td-word-wrap {
  white-space: nowrap;
}

.td-name-data {
  width: 400px;
}

.background-input {
  background-color: #f2faf5;
}
